import {
  Accordion,
  Button,
  Card,
  ErrorField,
  Htag,
  InstagramFeed,
  ModalStyled,
  StepCircle,
} from "components";
import "../../styles/global-style.css";
import s from "./Home.module.css";
import cn from "classnames";

import {
  ai,
  d_templates,
  divorce,
  Earl_Wellbeing_logo,
  Guardian_logo,
  independent_online_logo,
  Mumset_logo,
  p_source,
  Stylist_logo,
  Sun_logo,
  Sunday_Times_logo,
  support,
} from "./Home.images";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import testimonialData from "mock/testimonials.json";

import { useEffect, useState } from "react";

import {
  setBoardingStep,
  setRegModalStatus,
} from "store/onBoarding/onboarding-reducer";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { Alert, Snackbar } from "@mui/material";

import { faq } from "./faq";

import { AB, ES, JH, VN } from "../Team/Team.images";
import { IsItRight } from "layout/IsItRight/IsItRight.component";
import { verifyEmailAction } from "store/onBoarding/onBoarding-thunk";
import { setToken, setUsedData } from "store/userInfo/userInfo-reducer";
import { GuideFormComponent } from "./guidForm/GuideForm.component";
import { openLoginModal } from "store/login/login-reducer";
import { Helmet } from "react-helmet";
import { lockBodyScroll } from "layout/header/nav/Nav.component";
import LogoSlider from "./logos.scroll";

const logosList = [
  Guardian_logo,
  independent_online_logo,
  Earl_Wellbeing_logo,
  Mumset_logo,
  Stylist_logo,
  Sun_logo,
  Sunday_Times_logo,
];

function Home() {
  const dispatch = useAppDispatch();
  const userToken = useAppSelector((state) => state.userState.token);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState("");
  const [hideLogin, setHideLogin] = useState(false);
  const [showGuidMsg, setShowGuidMsg] = useState(false);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  const handleOpenReg = () => dispatch(setRegModalStatus(true));
  const handleResize = () => setIsMobile(window.innerWidth <= 768);

  useEffect(() => {
    window.addEventListener("resize", () => handleResize);
    return () => window.removeEventListener("resize", () => handleResize);
  }, []);

  useEffect(() => {
    const id = setTimeout(() => {
      setShowGuidMsg(true);
    }, 8000);

    const guidePopup = localStorage.getItem("guidePopup");

    if (guidePopup) {
      clearTimeout(id);
    }
  }, []);

  useEffect(() => {
    const hash = searchParams.get("verify_hash");

    if (!hash) return; // need show message error

    dispatch(verifyEmailAction(hash))
      .unwrap()
      .then((res) => {
        dispatch(setToken(res.token));

        localStorage.setItem("userToken", res.token);
        dispatch(setUsedData(res.user));
        dispatch(setRegModalStatus(true));
        dispatch(setBoardingStep(2));
        lockBodyScroll();
      })
      .catch((error) => {
        console.log(error);
        setError("The verify code is expired or already was used.");
      });
  }, [searchParams]);

  const [homeSettings, setHomeSettings] = useState({
    nlError: "",
    open: false,
    openIsItRight: false,
  });

  const handleCloseGuide = () => {
    setShowGuidMsg(false);
    localStorage.setItem("guidePopup", "true");
  };

  return (
    <>
      <Helmet defaultTitle="Online Divorce Platform | SeparateSpace">
        <title>Online Divorce Platform | SeparateSpace</title>
        <meta
          name="description"
          content="SeparateSpace - Your trusted partner for navigating separation and divorce. Find resources, tools, and support to manage your separation journey effectively."
        />
      </Helmet>
      <LogoSlider logos={logosList} />
      <section className={cn("section", s.team_section)}>
        <Htag tag="h1">Created by experts</Htag>
        <p className={s.subtitle}>
          SeparateSpace gives you the specialist support you need to get through
          divorce.
        </p>
        <div className={s.team}>
          <div className={s.member}>
            <div className={s.imgContainer}>
              <img src={AB} alt="" />
            </div>
            <Htag tag="h4">Amanda Bell</Htag>
            <p>Legal Expert</p>
          </div>
          <div className={s.member}>
            <div className={s.imgContainer}>
              <img src={VN} alt="" />
            </div>
            <Htag tag="h4">Victoria Nottage</Htag>
            <p>Legal Expert</p>
          </div>
          <div className={s.member}>
            <div className={s.imgContainer}>
              <img src={JH} alt="" />
            </div>
            <Htag tag="h4">Joanna Harrison</Htag>
            <p>Relationships Expert</p>
          </div>
          <div className={s.member}>
            <div className={s.imgContainer}>
              <img src={ES} alt="" />
            </div>
            <Htag tag="h4">Dr Ellen Shumka</Htag>
            <p>Psychologist</p>
          </div>
        </div>
        <Button apearance="secondary" onClick={() => navigate(`/team`)}>
          Meet our team
        </Button>
      </section>
      <section className={cn("section", s.section_services)}>
        <Htag tag="h1">Everything in one platform</Htag>
        <p>
          Every member gets an orientation call, 15 high-impact minutes with one
          of our founders to help you get the most out of your membership.
          <br /> Your membership includes:
        </p>
        <div className={s.services}>
          <div className={s.services_item}>
            <div className={s.services_item_img}>
              <img src={divorce} alt="" />
            </div>
            <div className={s.services_item_content}>
              <Htag tag="h4">Divorce pathway</Htag>
              <p>
                Personalised, interactive pathway with step-by-step expert
                guidance tailored to your unique circumstances. We guide you
                through the legal, financial, and practical phases of your
                divorce.
              </p>
            </div>
          </div>
          <div className={s.services_item}>
            <div className={s.services_item_img}>
              <img src={d_templates} alt="" />
            </div>
            <div className={s.services_item_content}>
              <Htag tag="h4">Divorce templates</Htag>
              <p>
                Emails, letters and documents drafted by our legal experts to
                assist you at critical moments.
              </p>
            </div>
          </div>
          <div className={s.services_item}>
            <div className={s.services_item_img}>
              <img src={p_source} alt="" />
            </div>
            <div className={s.services_item_content}>
              <Htag tag="h4">Parenting resources</Htag>
              <p>
                Legal insights and practical guidance to navigate parenting
                arrangements. Scripts prepared by experts across family law,
                child psychology, therapy and education.
              </p>
            </div>
          </div>
          <div className={s.services_item}>
            <div className={s.services_item_img}>
              <img src={ai} alt="" />
            </div>
            <div className={s.services_item_content}>
              <Htag tag="h4">AI divorce assistant</Htag>
              <p>
                Atla AI is trained on our expert knowledge and can answer your
                questions anytime.
              </p>
            </div>
          </div>
          <div className={s.services_item}>
            <div className={s.services_item_img}>
              <img src={support} alt="" />
            </div>
            <div className={s.services_item_content}>
              <Htag tag="h4">Member support groups</Htag>
              <p>Live, member-only webinars and Q&A sessions with experts.</p>
            </div>
          </div>
        </div>
        {!hideLogin && (
          <Button onClick={handleOpenReg} apearance="secondary">
            Sign up
          </Button>
        )}
      </section>

      <section className={cn("section", s.testimonials)}>
        <div className={s.testimonialHeader}>
          <Htag tag="h1">What our members think</Htag>
        </div>
        <div className={s.testimonialsList}>
          {testimonialData.map((testimonialData, index) => {
            if (isMobile) {
              if (index < 3)
                return (
                  <Card key={index} className={s.testimonialsList_item}>
                    {/* <Rating rate={testimonialData.rate} /> */}
                    <Htag tag="h4">{testimonialData.headline}</Htag>
                    <div className={s.testimonialsList_content}>
                      {testimonialData.value}
                    </div>
                    <h4>{testimonialData.author}</h4>
                  </Card>
                );
            } else {
              return (
                <Card key={index} className={s.testimonialsList_item}>
                  {/* <Rating rate={testimonialData.rate} /> */}
                  <Htag tag="h4">{testimonialData.headline}</Htag>
                  <div className={s.testimonialsList_content}>
                    {testimonialData.value}
                  </div>
                  <h4>{testimonialData.author}</h4>
                </Card>
              );
            }
          })}
        </div>
      </section>
      <section className={cn("section", s.click_way)}>
        <Htag tag="h1" className={s.click_way_header}>
          How it works
        </Htag>
        <ul>
          <li>
            <StepCircle
              className={cn(s.click_way_step)}
              size="M"
              backgroundColor="var(--third-logo-color)"
            >
              1
            </StepCircle>
            <Htag tag="h4">Sign up</Htag>
            <p>
              Choose a subscription and tell us about you and your
              circumstances.
            </p>
          </li>
          <li>
            <StepCircle
              className={cn(s.click_way_step)}
              size="M"
              backgroundColor="var(--third-logo-color)"
            >
              2
            </StepCircle>
            <Htag tag="h4">Get clarity</Htag>
            <p>
              We’ll create your personalised divorce pathway and tailored
              package of guides, templates and real life examples for each stage
              of the process.
            </p>
          </li>
          <li>
            <StepCircle
              className={cn(s.click_way_step)}
              size="M"
              backgroundColor="var(--third-logo-color)"
            >
              3
            </StepCircle>
            <Htag tag="h4">Take action</Htag>
            <p>
              Understand what you need to do, how to do it and move forward with
              confidence.
            </p>
          </li>
        </ul>
      </section>

      <section className={cn("section", s.faq)}>
        <Htag tag="h1">FAQ</Htag>
        <Accordion accordionData={faq}></Accordion>
      </section>
      <section className={s.newsletter_section}>
        <GuideFormComponent />
      </section>
      <InstagramFeed />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={homeSettings.open}
        autoHideDuration={6000}
        onClose={() => setHomeSettings({ ...homeSettings, open: false })}
      >
        <Alert
          onClose={() => setHomeSettings({ ...homeSettings, open: false })}
          severity={!homeSettings.nlError ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {!homeSettings.nlError
            ? "Thanks for subscribing.  We’ll be in touch soon."
            : homeSettings.nlError}
        </Alert>
      </Snackbar>
      <IsItRight
        open={homeSettings.openIsItRight}
        close={() => {
          setHomeSettings({
            ...homeSettings,
            openIsItRight: !homeSettings.openIsItRight,
          });
        }}
      />
      <ModalStyled
        open={!!error}
        close={() => setError("")}
        className={s.verified_error_modal}
      >
        <p>
          <ErrorField error={error} />
        </p>
        {userToken ? (
          <p>
            Go to{" "}
            <span
              className={"hover-underline-animation_back"}
              onClick={() => {
                dispatch(setRegModalStatus(true));
                dispatch(setBoardingStep(3));
              }}
            >
              pricing process
            </span>{" "}
            or{" "}
            <NavLink
              className={"hover-underline-animation_back"}
              to="/dashboard"
            >
              your dashboard
            </NavLink>
          </p>
        ) : (
          <p>
            Please, if you already have an account,{" "}
            <span
              onClick={() => dispatch(openLoginModal(true))}
              className={"hover-underline-animation_back"}
            >
              log in
            </span>{" "}
            or{" "}
            <span
              onClick={() => dispatch(setRegModalStatus(true))}
              className={"hover-underline-animation_back"}
            >
              become our member
            </span>{" "}
          </p>
        )}
      </ModalStyled>

      <ModalStyled open={!!showGuidMsg} close={() => handleCloseGuide()}>
        <GuideFormComponent />
      </ModalStyled>
    </>
  );
}

export default Home;
