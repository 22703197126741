import { Htag, LogoText } from "components";
import { TemplateProps } from "./templates.props";
import cn from "classnames";
import parse from "html-react-parser";
import s from "../PageTemplate.module.css";
import { GreatingSection } from "pages/Home/greatingSection.component";
import { MarketingNewsletter } from "forms/marketing_newsletter.componen";
import { useEffect, useState } from "react";
import { useAppDispatch } from "utils/hooks";
import { loadSinglePage } from "store/simplePages/simplePages-thunk";
import { Page } from "store/simplePages/simplePages-inderfaces";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import { MarketingNewsletter2 } from '../../../forms/marketing_newsletter.componen2';

export const MarketingTemplateComponent = ({ page }: TemplateProps) => {
  const [image, setImage] = useState("");
	const [title, setTitle] = useState("")

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadSinglePage(Number(page.id)))
      .unwrap()
      .then((resp: Page) => {
        if (resp.image) setImage(resp.image);
		setTitle(page.title)
      });
  }, [page.id]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={page.meta?.description} />
      </Helmet>
      <header className={s.templateHeader}>
        <NavLink to="/">
          <LogoText />
        </NavLink>
      </header>
      <>
        <div className={s.templateContent}>
          <div>
            <div className={cn(s.templatePage, "section")}>
              {parse(page.content)}
            </div>
          </div>
          <div className={s.pdfImg}>
            <img src={image} alt="" />
          </div>
        </div>
        <div className={s.formContainer}>
          {title && page.form_type == 1 && (
            <MarketingNewsletter title={title} />
          )}
          {title && page.form_type == 2 && (
            <MarketingNewsletter2 title={title} />
          )}
        </div>
        <GreatingSection />
        <section className={cn(s.aboutUs, s.coral_bg)}>
          <div className={cn(s.aboutUs_container)}>
            <div className={s.feature_content}>
              <Htag tag="h2">About Us</Htag>
              <p>
                SeparateSpace is the expert-powered platform that helps you sort
                out your parenting arrangements, property and finances when
                going through divorce.
              </p>
              <p>
                Helping you take control of your divorce and save on legal fees.
              </p>
            </div>

            <img src="assets/images/hero.png" alt="" />
          </div>
        </section>
     
      </>
    </>
  );
};
