import axios from "axios";
import { useEffect, useState } from "react";
import { GET_POSTS } from "../../store/config";
import { Htag } from "../Htag/Htag.component";

import s from "./InstagramFeed.module.css";
import cn from "classnames";
import { NoImgIcon } from "../Icons/Icons.component";

interface Feed {
  media_url: string;
  caption: string;
  permalink: string;
  media_type: "CAROUSEL_ALBUM" | "VIDEO" | "IMAGE";
}

export const InstagramFeed = (): JSX.Element => {
  const [feeds, setFeeds] = useState<Feed[]>([]);

  useEffect(() => {
    axios
      .get(GET_POSTS(), {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        responseType: "json",
      })
      .then((res) => {
        setFeeds(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className={cn("section", s.instaWrap)}>
      <Htag tag="h1">Follow us on Instagram</Htag>
      <div className={s.feedList}>
        {feeds.map((feed, index) => (
          <div key={index} className={s.feedItem}>
            {!feed.media_url ? (
              <NoImgIcon className={s.defaultImg} />
            ) : feed.media_type == "CAROUSEL_ALBUM" ||
              feed.media_type == "IMAGE" ? (
              <img className={s.feedImg} src={feed.media_url} />
            ) : (
              <video controls className={s.videoFeed}>
                <source src={feed.media_url} type="video/mp4"></source>
              </video>
            )}

            <div className={s.caption}>
              <Htag tag="h5">{feed.caption}</Htag>
            </div>
            <a href={feed.permalink} target="_blank"></a>
          </div>
        ))}
      </div>
    </div>
  );
};
