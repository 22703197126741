import { Link, NavLink } from "react-router-dom";
import { AccountIcon, Button, FullModalStyled } from "components";

import styles from "./Nav.module.css";
import { NavProps } from "./Nav.props";
import cn from "classnames";
import { useEffect, useState } from "react";

import { Login } from "../../auth/Login/Login.component";
import menu from "mock/navigation.json";
import { OnBoardingComponent } from "../../auth/Registration/OnBoarding.component";
import { ForgotPass } from "../../auth/ForgotPass/ForgotPass.component";
import {
  openLoginModal,
  openPassModal,
  resetToDefault,
} from "store/login/login-reducer";
import {
  setInterviewToInit,
  setRegModalStatus,
} from "store/onBoarding/onboarding-reducer";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import dayjs from "dayjs";
import { logoutAction } from "store/login/login-thunk";
import { createPortal } from "react-dom";

export function lockBodyScroll() {
  const scrollY = window.scrollY;
  document.body.style.position = "fixed";
  document.body.style.top = `-${scrollY}px`;
}

export function unlockBodyScroll() {
  const scrollY = parseInt(document.body.style.top || "0", 10) * -1;
  document.body.style.position = "";
  document.body.style.top = "";
  window.scrollTo(0, scrollY);
}

export const TopNav = ({ className, ...props }: NavProps): JSX.Element => {
  const sidebarContentEl = (document.getElementById("nav") as HTMLElement)
    ? (document.getElementById("nav") as HTMLElement)
    : document.body;

  const [container, setContainer] = useState<HTMLElement>(sidebarContentEl);

  const userToken = useAppSelector((state) => state.userState.token);
  const loginModalStatus = useAppSelector(
    (state) => state.onLogin.openLoginModal
  );

  const passwordModalStatus = useAppSelector(
    (state) => state.onLogin.openPassModal
  );
  const regModalStatus = useAppSelector(
    (state) => state.onRegistration.openModal
  );

  const role = useAppSelector((state) => state.userState.user.role);
  let dashboardUrl = "/dashboard";
  if (role == "admin") {
    dashboardUrl = "/admin-dashboard";
  }
  const [entrance, setEntrance] = useState(false);
  const [openNav, setOpenNav] = useState(false);
  const expired_date = useAppSelector((state) => state.userState.expired_at);
  const handleClick = () => {
    setEntrance((entrance) => !entrance);
  };

  const [diff, setDiff] = useState<number>();

  // REGISTRATION //

  const handleOpenReg = () => {
    dispatch(setRegModalStatus(true));
    lockBodyScroll();
  };
  const handleCloseReg = () => {
    unlockBodyScroll();

    dispatch(setInterviewToInit());
    dispatch(setRegModalStatus(false));
    dispatch(resetToDefault());
    setEntrance(false);
  };

  // LOGIN //

  const handleOpenLogin = () => {
    dispatch(openLoginModal(true));
    lockBodyScroll();
  };
  const handleClose = () => {
    dispatch(openLoginModal(false));
    dispatch(resetToDefault());
    unlockBodyScroll();
  };
  const dispatch = useAppDispatch();

  const signOut = () => {
    dispatch(logoutAction());
    dispatch(resetToDefault());
  };

  // Forgot Pass

  const handleClosePass = () => {
    dispatch(openPassModal(false));
    dispatch(resetToDefault());
    unlockBodyScroll();
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
  const sidebarContentEl = (document.getElementById("nav") as HTMLElement)
        ? (document.getElementById("nav") as HTMLElement)
        : document.body;
    const updateContainer = () => {
    
      setContainer(mediaQuery.matches ? document.body : sidebarContentEl);
    };

    updateContainer();
    // Listen for both resize and matchMedia changes
    window.addEventListener("resize", updateContainer);

    return () => {
      window.removeEventListener("resize", updateContainer);
    };
  }, []);

  useEffect(() => {
    if (expired_date) {
      const diference = dayjs(expired_date).diff(dayjs(Date.now()));
      setDiff(diference);

      if (diference !== undefined && diference < 0) {
        dispatch(openLoginModal(true));
        signOut();
      } else {
        const sInterval = setInterval(() => {
          dispatch(openLoginModal(true));
          signOut();
          clearInterval(sInterval);
        }, diference);
      }
    }
  }, []);

  return (
    <>
      <nav id="nav" className={cn(className, styles.nav)} {...props}>
        <input
          className={styles.checkbox}
          type="checkbox"
          checked={openNav}
          onChange={() => {
            setOpenNav((openNav) => !openNav);
            setEntrance(false);
          }}
          name=""
          id=""
        />
        {createPortal(
          <ul
            className={cn(styles.menu, {
              [styles.mobile_show]: openNav === true,
            })}
          >
            {menu.map((rItem, i) => (
              <li key={i} className={styles.menuItem}>
                <NavLink
                  className={({ isActive }) =>
                    [
                      isActive
                        ? "hover-underline-animation_back"
                        : "hover-underline-animation",
                    ]
                      .filter(Boolean)
                      .join(" ")
                  }
                  to={rItem.to}
                  onClick={() => setOpenNav(false)}
                  end
                >
                  {rItem.text}
                </NavLink>
              </li>
            ))}
          </ul>,

          container
        )}
        {createPortal(
          <div
            className={cn(styles.entrances, {
              [styles.mobile_show]: entrance === true,
            })}
          >
            {!userToken ? (
              <div className={styles.nav_logout_icons}>
                <Button onClick={handleOpenLogin} apearance="default">
                  Log in
                </Button>
                <Button onClick={handleOpenReg} apearance="secondary">
                  Sign up
                </Button>
              </div>
            ) : (
              <div className={styles.nav_logout_icons}>
                <Link to={dashboardUrl}>
                  <AccountIcon />
                </Link>

                <Button onClick={signOut} apearance="secondary">
                  Log out
                </Button>
              </div>
            )}
          </div>,
          container
        )}

        <AccountIcon onClick={handleClick} className={styles.mobile_entrance} />
        <div className={styles.hamburger_lines}>
          <span className={cn(styles.line, styles.line1)}></span>
          <span className={cn(styles.line, styles.line2)}></span>
          <span className={cn(styles.line, styles.line3)}></span>
        </div>
      </nav>

      <FullModalStyled
        open={loginModalStatus}
        color="light"
        close={handleClose}
      >
        <Login />
      </FullModalStyled>

      <FullModalStyled
        open={regModalStatus}
        close={handleCloseReg}
        color="light"
      >
        <OnBoardingComponent />
      </FullModalStyled>
      <FullModalStyled
        open={passwordModalStatus}
        close={handleClosePass}
        color="light"
      >
        <ForgotPass />
      </FullModalStyled>
    </>
  );
};
