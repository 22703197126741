import { Checkbox, FormControlLabel } from "@mui/material";
import { Button, ErrorField, Htag } from "components";
import { useFormik } from "formik";
import s from "./TC.module.css";
import * as yup from "yup";
import { useAppDispatch } from "utils/hooks";
import { setShowCalendy } from "store/coaching/coaching.reducer";
import { NavLink } from "react-router-dom";
import Privacy from "./Privacy_Policy.pdf";
import Terms from "./Terms&Conditions.pdf";

export const validationSignupSchema = yup.object({
  terms: yup.bool().oneOf([true]),
});
export const TCComponent = () => {
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      terms: false,
      //   showProfile: false,
    },
    validationSchema: validationSignupSchema,
    onSubmit: () => {
      dispatch(setShowCalendy(true));
    },
  });

  return (
    <>
      <Htag tag="h1">Terms and conditions</Htag>
      <form id="form-1" className={s.termsForm} onSubmit={formik.handleSubmit}>
        <p>
          To book your session you will need to agree to the{" "}
          <a
            className="hover-underline-animation_back"
            target="_blank"
            href={"/coaching-terms-and-conditions"}
          >
            terms and conditions
          </a>{" "}
          of our divorce expert. This is the{" "}
          <a
            className="hover-underline-animation_back"
            href="/privacy-policy"
            target="_blank"
          >
            privacy policy{" "}
          </a>{" "}
          that applies.
        </p>

        <div className={s.checkboxes}>
          <FormControlLabel
            control={
              <Checkbox
                name="terms"
                color="default"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            }
            label="I understand and agree to the terms and conditions"
          />
          {!formik.isValid && (
            <ErrorField
              error={
                "To proceed you need to accept the terms and conditions of our divorce expert."
              }
            />
          )}
          <Button apearance="dark" type="submit">
            Continue
          </Button>
        </div>
      </form>
    </>
  );
};
