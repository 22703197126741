import { Button, Htag } from "components";
import coach from "./coach.json";
import { Coach } from "./Coach/Coach.component";
import cn from "classnames";
import s from "./coaching.module.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import img from './img.jpg';

export const CoachList = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const intro_call: string | null = searchParams.get("intro_call");
  const navigation = useNavigate();
  const introContext: JSX.Element =
    intro_call !== null ? (
      <>
        <Htag tag="h1">Book your 15 minute one-to-one orientation call</Htag>
        <p>
          Keen to make the most of your SeparateSpace membership? Join one of
          our founders as an essential first step in your journey.{" "}
        </p>
        <div className={s.coachImg}>
          <img src={img} alt="" />
        </div>
        <Htag tag="h2">In just 15 high-impact minutes, you’ll:</Htag>
        <ul className="ul">
          <li>
            Expert guidance: Get personalised insights tailored to your divorce
            journey.
          </li>
          <li>
            Top tips: Learn how to fully utilise your SeparateSpace membership.
          </li>
          <li>Empowerment: Leave feeling confident and full of hope.</li>
        </ul>
        <Htag tag="h2">How it works</Htag>
        <ul className="ol">
          <li>Book a convenient time.</li>
          <li>Meet one of our founders online. </li>
          <li>
            Make the most of your SeparateSpace membership and move forward with
            confidence.
          </li>
        </ul>
        <Button
          apearance="dark"
          onClick={() => {
            navigation(`/dashboard/orientation-call/`);
          }}
          size="M"
        >
          Book video call
        </Button>
      </>
    ) : (
      <>
        <Htag tag="h1">Speak to a SeparateSpace Divorce Coach</Htag>
        <p>
          Stuck with your divorce? Our experienced SeparateSpace Divorce Coaches
          can help. Book a one-to-one session and take your next step forward.
        </p>
        <Htag tag="h2">How our SeparateSpace Divorce Coaches can help</Htag>
        <p>
          SeparateSpace Divorce Coaching offers a confidential space for you to
          talk about your concerns and get help with navigating your next steps.
        </p>

        <ul className="ul">
          <li>
            Get clear about the legal process, including whether you need a
            lawyer and how to manage costs.
          </li>
          <li>
            Understand the finances so you can plan confidently for negotiations
            and beyond.
          </li>
          <li>
            Get help with supporting your children and managing their
            transition, with practical guidance and strategies.
          </li>
          <li>
            Talk through your fears and frustrations in a safe, empathetic
            space.
          </li>
        </ul>
        <Htag tag="h2">Who are our SeparateSpace Divorce Coaches?</Htag>
        <p>
          Each of our divorce coaches have over 10 years of experience
          supporting people as they separate. They use their extensive
          experience to give practical and emotional support to people at all
          stages of their divorce.
        </p>
        <Htag tag="h3">How it works</Htag>
        <ol className="ol">
          <li>Book a convenient time.</li>
          <li>Meet your divorce expert online.</li>
          <li>Move forward with confidence.</li>
        </ol>
        <Htag tag="h2">Your SeparateSpace Divorce Coaches</Htag>
        <Coach />
      </>
    );

  return (
    <section className={cn(s.coaching_section, "section")}>
      {introContext}
    </section>
  );
};
