import { Checkbox, FormControlLabel } from "@mui/material";
import { Button, ErrorField, Htag } from "components";
import { useFormik } from "formik";
import s from "./TC.module.css";
import * as yup from "yup";
import { useAppDispatch } from "utils/hooks";
import { setShowCalendy } from "store/coaching/coaching.reducer";
import { Link } from "react-router-dom";

export const validationSignupSchema = yup.object({
  terms: yup.bool().oneOf([true]),
});
export const TCFreeCallComponent = () => {
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      terms: false,
      //   showProfile: false,
    },
    validationSchema: validationSignupSchema,
    onSubmit: () => {
      dispatch(setShowCalendy(true));
    },
  });

  return (
    <>
      <Htag tag="h1">Terms and conditions</Htag>
      <form id="form-1" className={s.termsForm} onSubmit={formik.handleSubmit}>
        <p>
          Our standard{" "}
          <Link
            to="/terms-of-service"
            className="hover-underline-animation_back"
          >
            terms of service
          </Link>{" "}
          apply. In addition, please note the following:
        </p>
        <ol className="ol">
          <li>
            All our members are eligible for an orientation call. Therefore, if
            your ex is also a member of SeparateSpace, they may also have an
            orientation call with us.
          </li>
          <li>
            We do not give legal advice. No lawyer-client relationship is
            created between you and SeparateSpace at any time.
          </li>
        </ol>

        <div className={s.checkboxes}>
          <FormControlLabel
            control={
              <Checkbox
                name="terms"
                color="default"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            }
            label="I understand"
          />
          {!formik.isValid && <ErrorField error={"I understand"} />}
          <Button apearance="dark" type="submit">
            Continue
          </Button>
        </div>
      </form>
    </>
  );
};
