import { Footer } from "layout";

import { Button, GoToTop } from "components";
import { useAppDispatch } from "utils/hooks";
import { resetToDefault } from "store/login/login-reducer";
import { MarketingLayoutProps } from "./MarketingLayout.props";
import { useEffect } from "react";

export const MarketingLayout = ({
  children,
}: MarketingLayoutProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const deleteAllCookies = () => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  };
  const hideSite = () => {
    window.open("https://en.wikipedia.org/wiki/Main_Page", "_newtab");
    location.replace("https://www.bbc.com/news");
    dispatch(resetToDefault());
  };

  const leaveSite = () => {
    hideSite();
    deleteAllCookies();
  };

  useEffect(() => {
    localStorage.removeItem("leave_site_sp");
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === "leave_site_sp") {
        hideSite();
        deleteAllCookies();
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <>
      <div>{children}</div>
      <Button
        apearance="secondary"
        style={{
          position: "fixed",
          top: "55%",
          right: "-39px",
          padding: "6px 4px",
          transform: "rotate(90deg)",
          width: "115px",
        }}
        onClick={leaveSite}
      >
        Leave site
      </Button>
      <Footer />
      <GoToTop />
    </>
  );
};
