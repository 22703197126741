import cn from "classnames";

import "../../styles/global-style.css";
import styles from "./Team.module.css";
import { AB, AR, CP, ES, JH, KR, VN, photo } from "./Team.images";
import { Htag, TeamBig } from "../../components";
import { GetInTouchTeam } from "./GetInTouchTeam/GetInTouchTeam.component";

import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { useState } from "react";
import Purpose from "pages/Purpose/Purpose.component";
import { Helmet } from "react-helmet";

const teamData = [
  {
    photoSrc: AB,
    name: "Amanda Bell",
    position: "Co-Founder",
    color: "coral_bg",
    details: (
      <p>
        Amanda spent the majority of her legal career in the market-leading,
        top-tier family law team at Withers LLP and has worked with hundreds of
        couples as they untangle their lives together. SeparateSpace was born
        out of her desire to help the ever-growing number of people who can’t
        access the support they need when they need it most.
      </p>
    ),
  },

  {
    photoSrc: JH,
    name: "Joanna Harrison",
    color: "green_bg",
    position: "Relationships Expert",
    details: (
      <p>
        Joanna is a former divorce lawyer, relationship therapist, and author.
        She has over 20 years of experience working with individuals and couples
        going through separation, helping them feel better in themselves and
        supporting them to have a calmer journey through divorce.
      </p>
    ),
  },

  {
    photoSrc: ES,
    name: "Dr Ellen Shumka",
    color: "green_bg",
    position: "Child Expert",
    details: (
      <p>
        Ellen is a registered psychologist with the College of Psychologists of
        Ontario and provides psychological assessment and treatment services to
        children, teens and their families. Ellen is passionate about giving
        parents the information and tools they need to make intentional choices
        about how to manage their separation and their co-parenting
        relationship.
      </p>
    ),
  },

  {
    photoSrc: AR,
    name: "Dr Angharad Rudkin",
    color: "coral_bg",
    position: "Child Expert",
    details: (
      <p>
        Angharad is a clinical psychologist with over 20 years experience
        working with children and their families and now trains family lawyers
        on child wellbeing during divorce. Angharad was the agony aunt for The
        Metro for many years, and regularly appears on TV and radio as an expert
        on child and family issues. She has co-authored a number of books on
        child development and has also written for children.
      </p>
    ),
  },
  {
    photoSrc: VN,
    name: "Victoria Nottage",
    color: "green_bg",
    position: "Co-Founder",
    details: (
      <p>
        Victoria has been a specialist family lawyer for over 18 years, gaining
        additional qualifications in counselling, coaching and mediation along
        the way. She is passionate about the SeparateSpace mission to positively
        transform the experience of divorce for families of all backgrounds.
      </p>
    ),
  },

  {
    photoSrc: KR,
    name: "Karen Ritchie",
    color: "green_bg",
    position: "Finance Expert",
    details: (
      <p>
        Karen is a financial planning specialist with over 20 years' experience
        supporting clients through separation and divorce. Her expertise lies in
        helping people to navigate financial decisions during challenging times,
        ensuring they achieve the best possible outcomes for their family. As
        part of the SeparateSpace team, Karen is passionate about making
        financial education and resources accessible to all. She understands
        that taking the first steps can feel overwhelming and is committed to
        providing clear, practical guidance when it's needed most.
      </p>
    ),
  },
];

function Team(): JSX.Element {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggle = (index: number | null): void => {
    setActiveIndex((prev) => (prev === index ? null : index));
  };
  return (
    <>
      <Helmet>
        <title>Our Expert Team | SeparateSpace</title>
        <meta
          name="description"
          content="Meet the SeparateSpace team. Our expert professionals in divorce, family law, and co-parenting."
        />
      </Helmet>

      <section className={cn("section", "innerPageBlock")}>
        <div className="imgBlock">
          <TeamBig />
        </div>
        <div className="contentBlock">
          <p>
            We are an ambitious and committed team working to revolutionise the
            way divorce support is delivered.
          </p>
          <p>
            SeparateSpace was founded in 2022 by Amanda and Victoria, who
            between them have more than 25 years experience as divorce and
            family lawyers and have supported hundreds of people as they
            divorce.
          </p>
        </div>
      </section>
      <section className={cn("section", styles.team_quotes)}>
        <div className={styles.team_quotes_text}>
          <p>
            “We guide our members through the complicated process of divorce and
            financial separation one step at a time. By providing personalised
            pathways, plus tailored resources and expert guidance, each of our
            members are on their unique path. But, with SeparateSpace, they're
            never alone.”
          </p>
        </div>
        <div className={styles.co_founders}>
          <img src={photo} alt="" />
        </div>
      </section>
      <section>
        <div className={styles.team_members_container}>
          {teamData.map((member, index) => (
            <div key={index} className={styles.team_member}>
              <div className={cn(styles.team_member_photo, member.color)}>
                <img src={member.photoSrc} alt="" />
              </div>
              <div className={styles.team_member_meta}>
                <Htag tag="h3">{member.name}</Htag>
                <p>{member.position}</p>
                <ArrowForwardIosOutlinedIcon
                  onClick={() => toggle(index)}
                  className={`${styles.downArrow} ${
                    activeIndex === index && styles.activeArrow
                  }`}
                />
              </div>
              <div
                className={`${styles.team_member_content} ${
                  activeIndex === index && styles.activeDetais
                }`}
              >
                {member.details}
              </div>
            </div>
          ))}
        </div>
      </section>
      <GetInTouchTeam />
      <Purpose />
    </>
  );
}

export default Team;
