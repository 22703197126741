import {
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import { Button, DropDownArrowIcon, ErrorField, Htag } from "components";
import { useFormik } from "formik";
import * as yup from "yup";
import cn from "classnames";
import s from "./marketing_newsletter.module.css";
import axios from "axios";
import { MEDIATOR_SEND, RSS_SEND } from "store/config";
import { ChangeEvent, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

export const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),

  consent: yup.bool().oneOf([true], "Please provide your consent to proceed"),
});

interface IDivorceStages {
  value: number;
  text: string;
}

const divorceStages: IDivorceStages[] = [
  {
    value: 1,
    text: "I mediate children issues",
  },
  {
    value: 2,
    text: "I mediate finance issues",
  },
  {
    value: 3,
    text: "I mediate children and finance issues",
  },
];

export const MarketingNewsletter2 = ({ title }: { title: string }) => {
  const [serverErr, setServerErr] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [selectDivorceStage, setSelectDivorceStage] =
    useState<IDivorceStages | null>(null);

  const formik = useFormik({
    initialValues: {
      email: "",
      consent: false,
      title: "",
      situation: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      axios
        .post(
          MEDIATOR_SEND(),
          {
            ...values,
            title: title,
            mediate: selectDivorceStage?.text,
          },
          {
            headers: {
              Accept: "application/json",
            },
          }
        )
        .then(() => {
          window.datahappy
            .trackLeadCreation({
              eventProperties: {
                value: 10, // common to track a value for lead events
                currency: "GBP",
                lead_source: "magnet",
              },
              userTraits: values,
            })
            .then(function () {
              const url = location.pathname.replace(/\/+$/, "");

              navigate(`${url}/thankyou`, { replace: true });
              setLoading(false);
            })
            .catch((err: Error) => {
              console.log(err);
            });
        })
        .catch((err) => {
          if (Array.isArray(err)) {
            setServerErr(err[0].email);
          } else {
            setServerErr("Server error, try later");
          }
          //
          setLoading(false);
        });
    },
  });

  const handleTypeChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSelectDivorceStage({
      value: +event.target.value,
      text: divorceStages[+event.target.value - 1].text,
    });
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit} className={s.form}>
        <Htag tag="h3">Email address</Htag>
        <FormControl fullWidth>
          <input
            id="email"
            name="email"
            type="text"
            className={cn(s.inputLogin, {
              [s.error_field]: formik.errors.email && formik.touched.email,
            })}
            placeholder="email"
            value={formik.values.email || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.email && formik.errors.email ? (
            <ErrorField error={formik.errors.email} />
          ) : null}
        </FormControl>
        <Htag className={s.mt20} tag="h3">
          Please tell us a bit about your work
        </Htag>
        <TextField
          variant="outlined"
          name="situation"
          id="situation"
          fullWidth
          select
          className={s.mb20}
          defaultValue={0}
          label={!selectDivorceStage ? "Choose…" : ""}
          InputLabelProps={{ shrink: false, disableAnimation: true }}
          value={selectDivorceStage?.value}
          onChange={handleTypeChange}
          sx={{
            "& .MuiSelect-iconOpen": { transform: "rotate(90deg)" },
            "& .MuiSelect-icon": { top: "7px" },
            "& .Mui-focused": {
              color: "#102D3E",
            },
            "& .MuiInputLabel-root": {
              top: "-10px",
            },
            "& .MuiOutlinedInput-root": {
              height: "45px",
            },
            "& .MuiOutlinedInput-root .MuiSelect-select": {
              padding: "0 22px",
              height: "45px",
              lineHeight: "45px",
            },
            "& .Mui-focused.MuiOutlinedInput-root": {
              border: "2px solid #91CCD1;",
            },
          }}
          SelectProps={{
            IconComponent: (props) => <DropDownArrowIcon {...props} />,
          }}
        >
          {divorceStages.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.text}
            </MenuItem>
          ))}
        </TextField>
        <p>
          <FormControlLabel
            value={formik.values.consent}
            className=""
            control={
              <Checkbox
                color="default"
                onBlur={formik.handleBlur}
                name="consent"
                onChange={formik.handleChange}
              />
            }
            label=""
          />
          <label>
            Consent to receive information from SeparateSpace. You can
            unsubscribe anytime. View our{" "}
            <NavLink
              className={cn(s.checkbox_link, "hover-underline-animation_back")}
              to="/privacy-policy"
            >
              privacy policy
            </NavLink>{" "}
            here
          </label>
        </p>

        {formik.touched.consent && formik.errors.consent && (
          <ErrorField error={formik.errors.consent} />
        )}

        {serverErr && <ErrorField error={serverErr} />}
        <Button
          apearance="secondary"
          type="submit"
          disabled={loading || !formik.isValid || !formik.dirty}
        >
          Send it to me
        </Button>
      </form>
    </>
  );
};
