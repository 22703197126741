import React, { useEffect, useState } from "react";
import s from "./logos.module.css";

interface LogoSliderProps {
  logos: string[];
  speed?: number; // Speed in seconds
  visibleDesktop?: number;
  visibleMobile?: number;
}

const LogoSlider: React.FC<LogoSliderProps> = ({
  logos,
  speed = 20,
  visibleDesktop = 7,
  visibleMobile = 3,
}) => {
  const [visibleCount, setVisibleCount] = useState<number>(visibleDesktop);

  useEffect(() => {
    const updateVisibleCount = () => {
		if (window.devicePixelRatio > 1) {	
			setVisibleCount(screen.width < 768 ? visibleMobile : visibleDesktop);
		} else {
			setVisibleCount(window.innerWidth < 768 ? visibleMobile : visibleDesktop);
		}
    };

    updateVisibleCount();
    window.addEventListener("resize", updateVisibleCount);
    return () => window.removeEventListener("resize", updateVisibleCount);
  }, [visibleDesktop, visibleMobile]);

  return (
    <div className={s.logo_slider_container}>
      <div
        className={s.logos_scroll}
        style={{ animationDuration: `${speed}s` }}
      >
        {[...logos, ...logos].map((logo, index) => (
          <div key={index} style={{ minWidth: `${100 / visibleCount}%` }}>
            <img src={logo} alt="logo" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LogoSlider;
