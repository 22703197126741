export const pageTemplates: { value: string | number; label: string }[] = [
  { value: "default", label: "Default" },
  { value: "marketing", label: "Marketing landing" },
];

export const formTypes: { value: number; label: string }[] = [
  {
    value: 1,
    label: "Get Checklist form",
  },
  {
    value: 2,
    label: "Get mediator Data",
  },
];
