import { useEffect, useState } from "react";
import { Button, Card, Htag } from "components";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import s from "./Dashboard.module.css";
import { useNavigate } from "react-router-dom";
import { IDivorceStep } from "store/divorceSteps/divorceSteps.interface";
import { loadDivorceSteps } from "store/divorceSteps/divorceSteps.thunk";
import TimeLineItem from "components/TimelineItem/TimeLineItem.component";
import dayjs from "dayjs";
import cn from "classnames";
import { DashboardStepsInfo } from "components/DashboardStepsInfo/DashboardStepsInfo.component";

export const Dashboard = (): JSX.Element => {
  const navigate = useNavigate();
  const [divorceSteps, setDivorceSteps] = useState<IDivorceStep[]>([]);
  const [unFinished, setUnfinished] = useState<IDivorceStep>();
  const token = useAppSelector((state) => state.userState.token);

  const [firstLoginDiff, setFirstLoginDiff] = useState<undefined | number>();
  const [firstLogin, setFirstLogin] = useState(undefined);
  const has_children = useAppSelector(
    (state) => state.userState.user.questionary?.has_children
  );

  const { name, email } = useAppSelector((state) => state.userState.user);

  const dispatch = useAppDispatch();

  const findUnfinishedDivorceStep = (arr: IDivorceStep[]) => {
    const value = Object.assign(
      {},
      arr.find((item) => item.complete_percent !== 100)
    );
    return value;
  };

  useEffect(() => {
    const getLocalStorageData = localStorage.getItem(email);

    if (getLocalStorageData) {
      const day = dayjs(getLocalStorageData);
      const diff = day.diff(new Date(), "day");

      setFirstLoginDiff(diff);
    } else {
      localStorage.setItem(email, new Date().toString());
    }
  }, []);

  useEffect(() => {
    dispatch(loadDivorceSteps())
      .unwrap()
      .then((res: IDivorceStep[]) => {
        setDivorceSteps(res);
        setUnfinished(findUnfinishedDivorceStep(res));
      });
  }, []);

  return (
    <>
      <header className={s.dashboardHeader}>
        <Htag tag="h1">
          Welcome{firstLoginDiff ? " back" : ``}, {name}
        </Htag>
      </header>
      <section className={s.divorceStepsWrap}>
        <Htag tag="h2" className="mb32">
          Here is your divorce pathway
        </Htag>
        <div className={s.divorceSteps}>
          {divorceSteps.map((divorceStep, index) => (
            <TimeLineItem
              index={index + 1}
              id={divorceStep.id.toString()}
              title={divorceStep.title}
              key={index}
              completed_percent={divorceStep.complete_percent}
            />
          ))}
        </div>
      </section>
      <DashboardStepsInfo divorceStep={unFinished} />

      <Card
        color="var(--modal-gray)"
        className={cn(s.text_left, s.actionCards)}
      >
        <Htag tag="h2">Book your 15 minute orientation call</Htag>
        <Button
          onClick={() => navigate("/dashboard/divorce-expert?intro_call")}
          apearance="default"
        >
          Book video call
        </Button>
      </Card>
      {has_children == "yes" && (
        <Card
          color="var(--modal-gray)"
          className={cn(s.text_left, s.actionCards)}
        >
          <Htag tag="h2">Focus on parenting</Htag>
          <div className={s.stepIntroText}>
            <img src="../assets/images/Vic.png" alt="" />
            <p>
              We’ve designed a special set of tasks related to parenting during
              divorce. It includes tools to help you reach an agreement for
              dividing time with your children, templates, and more.
            </p>
          </div>
          <Button
            onClick={() => navigate("/dashboard/parenting")}
            apearance="default"
          >
            Parenting resources
          </Button>
        </Card>
      )}
      <Card
        color="var(--modal-gray)"
        className={cn(s.text_left, s.actionCards)}
      >
        <Htag tag="h2">Atla AI</Htag>
        <div className={s.stepIntroText}>
          <img src="../assets/images/atla.png" alt="" />
          <p>
            A chatbot which can help you through the divorce process, offer tips
            on practicalities, supporting children and handling finances. It can
            point you to useful templates and resources.
          </p>
        </div>
        <Button
          onClick={() => navigate("/dashboard/atlaAI")}
          apearance="default"
        >
          Ask Atla AI
        </Button>
      </Card>
      <Card
        color="var(--modal-gray)"
        className={cn(s.text_left, s.actionCards)}
      >
        <Htag tag="h2">Prioritise your wellbeing</Htag>
        <div className={s.stepIntroText}>
          <img src="../assets/images/Vic2.png" alt="" />
          <p>
            Guides to effective communication, resolving conflict, and managing
            emotions written by relationship experts and psychologists.
          </p>
        </div>
        <Button
          onClick={() => navigate("/dashboard/my-wellbeing")}
          apearance="default"
        >
          Wellbeing resources
        </Button>
      </Card>

      <Card
        color="var(--modal-gray)"
        className={cn(s.text_left, s.actionCards)}
      >
        <Htag tag="h2">Speak to an expert</Htag>
        <div className={s.stepIntroText}>
          <img src="../assets/images/am.png" alt="" />
          <p>
            50 minutes one-to-one with a SeparateSpace Divorce Coach. Bookable
            on-demand for £125.
          </p>
        </div>
        <Button onClick={() => navigate("divorce-expert")} apearance="default">
          Book video call
        </Button>
      </Card>
    </>
  );
};
