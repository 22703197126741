import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ATLABOTAPI,
  GET_AI_REPORT,
  GETFILESFORAPI,
  SAVE_REPORT,
} from "store/config";
import { IInitState } from "store/root-interface";
import { IAtlaResponse, IChatBotFile, IChatPrompt } from "./atla-interface";
import { API_TYPE, getRequestFabric } from "store/thunk-helper";

export const atlaSendPrompt = createAsyncThunk<
  IAtlaResponse,
  { message: string },
  { getState: IInitState; rejectValue: string }
>(
  "@@atla/sendPrompt",
  async function (data, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;
    const header = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response1 = await fetch(ATLABOTAPI(), header);
    if (!response1.ok) {
      return response1
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.errors);
        });
    }
    const data1 = await response1.json();
    dispatch(
      atlaSavePrompt({ question: data.message, answer: data1.response })
    );
    let mixedData: IAtlaResponse;

    if (data1.response_details.length > 0) {
      const keys = data1.response_details.map(
        (item: IChatBotFile) => item.section_id
      );
      const arr = new Set(keys);

      const header2 = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userState.token,
        },
        body: JSON.stringify({
          unique_numbers: [...arr],
        }),
      };

      const response2 = await fetch(GETFILESFORAPI(), header2);

      if (!response2.ok) {
        throw new Error("Failed to fetch data from endpoint2");
      }

      const data2 = await response2.json();

      mixedData = {
        ...data1,
        articleInfo: data2,
      };
    } else {
      mixedData = {
        ...data1,
        articleInfo: undefined,
      };
    }

    return mixedData;
  }
);

export const atlaSavePrompt = createAsyncThunk<
  { message: string },
  { question: string; answer: string },
  { getState: IInitState; rejectValue: string }
>(
  "@@atla/savePrompt",
  async function (data, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST,
      getState,
      SAVE_REPORT(),
      rejectWithValue,
      dispatch,
      JSON.stringify(data)
    );
  }
);

export const atlaGetAIReport = createAsyncThunk<
  { message: string },
  string,
  { getState: IInitState; rejectValue: string }
>(
  "@@atla/getAiPrompt",
  async function (date, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST,
      getState,
      GET_AI_REPORT(),
      rejectWithValue,
      dispatch,
     JSON.stringify( { date: date })
    );
  }
);
