import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  DELETE_MEMBER,
  GET_SUBSCRIBER,
  GET_SUBSCRIBERS_LIST,
  GET_WAITING_LIST,
  RESET_USER_DATA,
} from "../config";
import { IInitState } from "../root-interface";
import {
  IGetRegUsersData,
  IMember,
  IgetUsersApiRequest,
  MemberType,
} from "./waitingList.interface";
import { getQueryString } from "utils/utils";
import { getRequestFabric, API_TYPE } from "../thunk-helper";

export const get_waiting_list = createAsyncThunk<
  IMember[],
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@waitingList/gettingWaitingList",
  async function (_, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_WAITING_LIST(),
      rejectWithValue,
      dispatch
    );
  }
);

export const get_subscribers_list = createAsyncThunk<
  IGetRegUsersData,
  IgetUsersApiRequest,
  { getState: IInitState; rejectValue: string }
>(
  "@@waitingList/gettingSubscribers",
  async function (data, { getState, rejectWithValue, dispatch }) {
    const { page, limit } = data;

    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      getQueryString(
        `${GET_SUBSCRIBERS_LIST()}?page=${page}&limit=${limit}`,
        data
      ),
      rejectWithValue,
      dispatch
    );
  }
);

export const get_subscriber = createAsyncThunk<
  MemberType,
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@waitingList/gettingSubscribers",
  async function (id, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_SUBSCRIBER(id),
      rejectWithValue,
      dispatch
    );
  }
);

export const deleteMember = createAsyncThunk<
  { success: string },
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@members/deleteMember",
  async function (id, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.DELETE,
      getState,
      DELETE_MEMBER(id),
      rejectWithValue,
      dispatch,
    );
  }
);


export const resetMember = createAsyncThunk<
  { success: string },
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@members/resetMember",
  async function (id, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.DELETE,
      getState,
      RESET_USER_DATA(id),
      rejectWithValue,
      dispatch
    );
  }
);
