import { useEffect, useState } from "react";
import { Post } from "store/blog/blog.interfaces";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "utils/hooks";
import { get_single_post } from "store/blog/blog-thunk";
import { Htag, ModalStyled } from "components";
import ReactHtmlParser from "html-react-parser";
import a from "components/ArticleContent/ArticleContent.module.css";
import s from "./Blog.module.css";
import cn from "classnames";
import dayjs from "dayjs";
import { Chip } from "@mui/material";
import { GuideFormComponent } from "pages/Home/guidForm/GuideForm.component";
import { RecentPostsComponent } from "./RecentPosts.component";
import { Helmet } from "react-helmet";

export const PostTemplate = () => {
  const [post, setPost] = useState<Post>({} as Post);

  const [showGuidMsg, setShowGuidMsg] = useState(false);
  const { slug } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const id = setTimeout(() => {
      setShowGuidMsg(true);
    }, 8000);

    const guidePopup = localStorage.getItem("guidePopup");

    if (guidePopup) {
      clearTimeout(id);
    }
  }, []);

  useEffect(() => {
    if (!slug) return;

    dispatch(get_single_post(slug))
      .unwrap()
      .then((resp) => {
        setPost(resp);
        if (!resp.id) return;
      });
  }, [slug]);

  const handleCloseGuide = () => {
    setShowGuidMsg(false);
    localStorage.setItem("guidePopup", "true");
  };

  return (
    <>
      <Helmet>
        <title>{post?.title?.toString() || ""}</title>
        <meta name="description" content={post.content?.slice(0, 50)} />
      </Helmet>
      <div className={cn(s.postWrap, "section")}>
    
        <Htag tag="h2" className={s.postTitle}>
          {post.title}
        </Htag>

        <div className={s.postMainImage}>
          <img src={post.image} alt={post.title} />
        </div>
        <div className={a.contentWrap}>
          {typeof post?.content == "string" && ReactHtmlParser(post.content)}
          <div className={s.hashtags}>
            <h5>Categories:</h5>
            {post.categories &&
              post.categories.map((tag: string, index) => (
                <Chip key={index} label={`${tag}`} />
              ))}
          </div>
          <div className={s.hashtags}>
            <h5>Tags:</h5>
            {post.tags &&
              post.tags.map((tag: string, index) => (
                <Chip key={index} label={`#${tag}`} variant="outlined" />
              ))}
          </div>
        </div>
      </div>
      <div className={cn(s.recentPosts, "section")}>
        <Htag tag="h2">Recent articles</Htag>
        <div className={cn(s.postsList)}>
          {post.id && <RecentPostsComponent id={post.id} />}
        </div>
      </div>
      <ModalStyled open={!!showGuidMsg} close={() => handleCloseGuide()}>
        <GuideFormComponent />
      </ModalStyled>
    </>
  );
};
