import { Button, Htag } from "components";
import { CoachProps } from "./Coach.props";
import { useNavigate } from "react-router-dom";
import s from "./coach.module.css";

export const Coach = (): JSX.Element => {
  const navigation = useNavigate();

  return (
    <div className={s.coaching}>
      <div className={s.coachItem}>
        <div className={s.coachImg}>
          <img src={"../assets/images/am.png"} alt="Amanda Bell" />
        </div>

        <Htag tag="h3">Amanda Bell</Htag>
        <div className="mb25">
          <p>
            Amanda worked as a family lawyer at a top tier legal practice before
            co-founding SeparateSpace. As a SeparateSpace Divorce Coach, Amanda
            supports people with one-to-one, bespoke sessions where she provides
            practical and actionable guidance.{" "}
          </p>
        </div>
      </div>
      <div className={s.coachItem}>
        <div className={s.coachImg}>
          <img src={"../assets/images/Vic.png"} alt="Vicky Nottage" />
        </div>

        <Htag tag="h3">Vicky Nottage</Htag>
        <div className="mb25">
          <p>
            Vicky is co-founder of SeparateSpace, a family lawyer by background
            and also a trained mediator. As a SeparateSpace Divorce Coach, Vicky
            provides one-to-one practical support helping people move forwards
            with clarity and confidence.
          </p>
        </div>
      </div>
      <Button
        apearance="dark"
        onClick={() => {
          navigation(`1/booking`);
        }}
        size="M"
      >
        Book video call
      </Button>
    </div>
  );
};
