import { Htag, TCComponent } from "components";
import { useEffect, useState } from "react";

import { InlineWidget } from "react-calendly";
import { sendTriggerByBooking } from "store/coaching/coach-thunk";
import { useAppDispatch, useAppSelector } from "utils/hooks";
export const BookYourTime = () => {
  const dispatch = useAppDispatch();

  const showCalendy = useAppSelector(
    (state) => state.onCoachingState.showCalendy
  );

  const [triggerSend, setTriggerSend] = useState<boolean>(false);

  const isCalendlyEvent = (e: any) => {
    return e.data.event && e.data.event.indexOf("calendly") === 0;
  };

  useEffect(() => {
    setTriggerSend(false);

    const handleMessageListener = (e: MessageEvent<any>) => {
      if (
        isCalendlyEvent(e) &&
        e.data.event == "calendly.event_scheduled" &&
        !triggerSend
      ) {
        setTriggerSend(true);
        dispatch(sendTriggerByBooking());
      }
    };

    window.addEventListener("message", handleMessageListener);

    return () => window.removeEventListener("message", handleMessageListener);
  }, []);

  return (
    <>
      {!showCalendy ? (
        <TCComponent />
      ) : (
        <>
          <Htag style={{ marginBottom: "-80px" }} tag="h1">
            Book your one to one session
          </Htag>
          <InlineWidget
            url="https://calendly.com/separatespace/separatespace-1-1-coaching-session"
            styles={{
              height: "800px",
              width: "100%",
              fontSize: "22px!important",
              color: "#102D3E!important",
            }}
            pageSettings={{
              hideEventTypeDetails: false,
              hideLandingPageDetails: false,
              primaryColor: "ff0000",
              textColor: "102D3E",
            }}
          />
        </>
      )}
    </>
  );
};
