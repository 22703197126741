export const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

const SOCKET_BASE_URL = process.env.REACT_APP_SOCKET_BASE_URL;

export const LOGIN = () => `${BASE_URL}/login`;
export const LOGOUT = () => `${BASE_URL}/logout`;
export const SIGNUP = () => `${BASE_URL}/register`;
export const FORGO_PASS = () => `${BASE_URL}/password/email`;
export const RESET_PASS = () => `${BASE_URL}/password/reset`;
export const ISITRIGHT = () => `${BASE_URL}/clarification/send`;
export const VERIFY_EMAIL = () => `${BASE_URL}/email/verify`;
export const RESEND_VERIFY_EMAIL = () => `${BASE_URL}/email/resend`;

export const MEMORY_CREATE = () => `${BASE_URL}/field/remember`;
export const MEMORY_DELETE = (field_id: string) =>
  `${BASE_URL}/field/${field_id}/forget`;

// InstagramFeed
export const GET_POSTS = () => `${BASE_URL}/feeds`;

// Stripe

export const CANCEL_STRIPE = () => `${BASE_URL}/stripe/subscription/cancel`;
export const RESUME_STRIPE = () => `${BASE_URL}/stripe/subscription/resume`;
export const REFUND_REQUEST_STRIPE = () => `${BASE_URL}/stripe/refund`;
export const REFUNDS_LIST_STRIPE = (page: number, limit: number) =>
  `${BASE_URL}/admin/refunds?page=${page}&limit=${limit}`;
export const REFUND_RELOAD_STRIPE = (refund_id: string) =>
  `${BASE_URL}/admin/refunds/${refund_id}/run`;
export const REFUND_DELETE_STRIPE = (refund_id: string) =>
  `${BASE_URL}/admin/refunds/${refund_id}/delete`;

// onboarding

export const QUESTIONNAIRE = () => `${BASE_URL}/user/questionary/save`; // SAVE DATA

//Divorce steps

export const GET_ALL_DIVORCE_STEPS = () => `${BASE_URL}/steps/`;
export const GET_DIVORCE_STEP = (step_id: number) =>
  `${BASE_URL}/step/${step_id}`;

// Parenting section
export const GET_PARENTING_SPACE = () => `${BASE_URL}/parenting`;

//Tasks
export const ALL_TASKS = (space: number) => `${BASE_URL}/user-tasks/${space}`; // do we need it?
export const GET_TASK = (id: string) => `${BASE_URL}/user-task/${id}`;
export const DELETE_TASK = (task_id: number | undefined) =>
  `${BASE_URL}/user-task/${task_id}/delete`; // Check if it's used
export const SET_TASK_STATUS = (task_id: number | undefined) =>
  `${BASE_URL}/user-task/${task_id}/toggle-status`;

//Pages - article

export const CREATE_PAGE_TEMPLATE = () => `${BASE_URL}/admin/page/create`;
export const UPDATE_PAGE_TEMPLATE = (page_id: number) =>
  `${BASE_URL}/admin/page/update/${page_id}`;
export const DELETE_PAGE_TEMPLATE = (page_id: number) =>
  `${BASE_URL}/admin/page/remove/${page_id}`;
export const GET_ALL_PAGES = () => `${BASE_URL}/pages`;
export const GET_PAGE = (page_id: number) => `${BASE_URL}/page/${page_id}`;

// Wellbeing
export const GET_ALL_CATEGORIES = (id: number) =>
  `${BASE_URL}/categories?type=${id}`;
export const CREATE_NEW_CATEGORY = () => `${BASE_URL}/admin/category/create`; // Create new challenge
export const UPDATE_CATEGORY = (id: number) =>
  `${BASE_URL}/admin/category/update/${id}`;
export const DELETE_CATEGORY = (id: number) =>
  `${BASE_URL}/admin/category/delete/${id}`;

//System wellbeing guides
export const GET_SYSTEM_WELLBEING_GUIDES = () =>
  `${BASE_URL}/admin/wellbeing/guides/system`;
export const ADD_WELLBEING_SYSTEM_GUIDE = () =>
  `${BASE_URL}/wellbeing/guides/system/addition`;

// Guides
export const GET_ALL_WELLBEING_GUIDES = () => `${BASE_URL}/wellbeing/guides`;
export const GET_SINGLE_WELLBEING_GUIDE = (id: number) =>
  `${BASE_URL}/wellbeing/guide/${id}`;
export const CREATE_GUIDE = () => `${BASE_URL}/admin/wellbeing/guide/create`;
export const UPDATE_GUIDE = (id: number) =>
  `${BASE_URL}/admin/wellbeing/guide/${id}/update`;
export const DELETE_GUIDE = (guide_id: number) =>
  `${BASE_URL}/admin/wellbeing/guide/${guide_id}/delete`;

// System tasks
export const ADD_TASKS_TO_DASHBOARD = () => `${BASE_URL}/tasks/adding-to-user`;
export const GET_TASKS_BY_ADMIN = () => `${BASE_URL}/admin/tasks`;
export const GET_TASK_BY_ADMIN = (task_id: number) =>
  `${BASE_URL}/admin/task/${task_id}`;
export const UPDATE_TASK_BY_ADMIN = (task_id: string) =>
  `${BASE_URL}/admin/task/${task_id}/update`;

// System guides
export const ADD_GUIDES_TO_DASHBOARD = () =>
  `${BASE_URL}/special-guides/addition`;
export const GET_SYSTEM_GUIDES = (type: number) =>
  `${BASE_URL}/special-guides?type=${type}`; // by type 1 (divorce steps) or 2 (parenting)
export const GET_SINGLE_GUIDE = (system_guide: number) =>
  `${BASE_URL}/special-guide/${system_guide}`;
export const UPDATE_SYSTEM_GUIDE = (guide_id: string) =>
  `${BASE_URL}/admin/special-guide/${guide_id}/update`;

// Attachments
export const DELETE_ATTACH = (id: number) =>
  `${BASE_URL}/admin/attachment/${id}/delete`;
export const DELETE_TASK_ATTACH = (id: number) =>
  `${BASE_URL}/admin/task/attachment/delete/${id}`;
export const DOWNLOAD_ATTACH = (id: number) =>
  `${BASE_URL}/attachment/${id}/download`;
export const DOWNLOAD_TASK_ATTACH = (id: number, task_id: number) =>
  `${BASE_URL}/task/attachment/download?task_id=${task_id}&attachment_id=${id}`;

// Socket
export const SOCKET_CONNECT = () => `${SOCKET_BASE_URL}`;

// User
export const CHANGE_PASS = () => `${BASE_URL}/user/password`;
export const GET_USER_DATA = () => `${BASE_URL}/is-user-logged`;
export const UPDATE_PROFILE = () => `${BASE_URL}/user/update`;
export const DELETE_PROFILE = () => `${BASE_URL}/user/delete`;
export const EMAIL_SUBSCRIPTION = () => `${BASE_URL}/user/subscription`;
export const RESET_USER_DATA = (user_id: number) => `${BASE_URL}/admin/user-data-clear/${user_id}`;

// Bookmarks
export const GET_ALL_BOOKMARKS = () => `${BASE_URL}/bookmarks`;
export const ADD_BOOKMARK = () => `${BASE_URL}/bookmark/create`;
export const REMOVE_BOOKMARK = () => `${BASE_URL}/bookmark/delete`;

// Extra guides
export const GET_ALL_EXTRA_GUIDES = () => `${BASE_URL}/articles`;
export const GET_EXTRA_RESOURCE = (id: number) => `${BASE_URL}/article/${id}`;
export const ADD_EXTRA_RESOURCES = () => `${BASE_URL}/admin/article/create`;
export const EDIT_EXTRA_RESOURCES = (id: string) =>
  `${BASE_URL}/admin/article/${id}/update`;
export const DELETE_EXTRA_RESOURCES = (id: number) =>
  `${BASE_URL}/admin/article/${id}/delete`;

// Waiting List
export const GET_WAITING_LIST = () => `${BASE_URL}/admin/applicants`;
export const GET_SUBSCRIBERS_LIST = () => `${BASE_URL}/admin/users`;
export const GET_SUBSCRIBER = (id: number) => `${BASE_URL}/admin/user/${id}`;
export const DELETE_MEMBER = (user_id: number) =>
  `${BASE_URL}/admin/user/remove?user_id=${user_id}`;

// Search
export const GET_ALL_RESULTS = (search: string) =>
  `${BASE_URL}/search?search=${search}`;

// Blog
export const GET_ALL_POSTS = (page: number, limit: number) =>
  `${BASE_URL}/blog/list?page=${page}&limit=${limit}`;
export const GET_SINGLE_POST = (page_id: string) =>
  `${BASE_URL}/blog/${page_id}`;
export const CREATE_POST = () => `${BASE_URL}/admin/blog/create`;
export const UPDATE_POST = (page_id: string) =>
  `${BASE_URL}/admin/blog/${page_id}/update`;
export const DELETE_POST = (slug: string) =>
  `${BASE_URL}/admin/blog/${slug}/remove`;
export const GET_RECENT_POSTS = (post_id: number) =>
  `${BASE_URL}/blog/recent?id=${post_id}`;

// Case Studies

export const GET_ALL_CS = () => `${BASE_URL}/case-studies/`;
export const GET_USER_SP_CS = () => `${BASE_URL}/user-case-studies/`;
export const GET_ALL_SP_CS = () => `${BASE_URL}/admin/case-studies-special/`;

export const GET_CS = (case_study_id: string) =>
  `${BASE_URL}/case-study/${case_study_id}`;
export const CREATE_CS = () => `${BASE_URL}/admin/case-study/create`;
export const UPDATE_CS = (case_study_id: string) =>
  `${BASE_URL}/admin/case-study/${case_study_id}/update`;
export const DELETE_CS = (case_study_id: number) =>
  `${BASE_URL}/admin/case-study/${case_study_id}/delete
`;
export const ADD_CS_KEYS = () =>
  `${BASE_URL}/case-study-addition
`;

// Analytics
export const GET_ANALITIC_DATA = () => `${BASE_URL}/admin/statistic`;
export const GET_USERS_DATA_STATISTIC = (time: number) =>
  `${BASE_URL}/admin/user-statistic?last_days=${time}`;
export const GET_USERS_DATA_ALL_STATISTIC = (time: number) =>
  `${BASE_URL}/admin/user-statistic-progress?last_days=${time}`;

export const GET_ACTIVITIES_DATA = () => `${BASE_URL}/admin/analytics`;

export const RSS_SEND = () => `${BASE_URL}/rss/send`;
export const MEDIATOR_SEND = () => `${BASE_URL}/mediator/send`;

export const GET_BOOKED_SESSION_PROGRESS = (time: number) =>
  `${BASE_URL}/admin/booked-session-progress?last_days=${time}`;

// Smart Banner
export const SB_GET = () => `${BASE_URL}/banner/show`;
export const SB_UPDATE = () => `${BASE_URL}/admin/banner/update`;
export const SB_CLEAR = () => `${BASE_URL}/admin/banner/clear`;

// experts

export const SEND_EBOOKING = () => `${BASE_URL}/call-to-expert`;

// ATLA BOT
export const ATLABOTAPI = () => `https://ss-backend.azurewebsites.net/chat`;
export const GETFILESFORAPI = () => `${BASE_URL}/workaround`;
export const SAVE_REPORT = () => `${BASE_URL}/ai/question-report`;
export const GET_AI_REPORT = () => `${BASE_URL}/admin/ai/question-report-send`;
